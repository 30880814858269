// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import { VDateInput } from "vuetify/labs/VDateInput";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import { VNumberInput } from "vuetify/labs/VNumberInput";

const myCustomTheme = {
  
  dark: false,
  colors: {
    primary: "#00ab6c",
    secondary: "#424242",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    primaryTextColor: "#012d21",
    // background: '#001e46',

    yellowColorVariant1: "#fac62a",

    greenColorVariant1: "#163020",
    greenColorVariant2: "#6cfacd",
    greenColorVariant3: "#6cfacd",
    greenColorVariant4: "#0c4832",
    greenColorVariant5: "#4baf8f",

    yellowColorVariant: "#fbcd2c",
    yellowColorVariant2: "#fff3cc",
   

    redColorVariant1: "#ff1919",
    redColorVariant2: "#FF5C5C",
    redColorVariant3: "#ba0f30",

    blueColorVariant1: "#e5e8ed",
    blueColorVariant2: "#24156d",
    blueColorVariant3: "#004aad",
    blueColorVariant14: "#2b4f60",
    blueColorVariant5: "#00c6dd",

    greyColorVariant1: "#e5e5e5",
    greyColorVariant2: "#efefdo",
    greyColorVariant3: "#e3dddc",
    greyColorVariant4: "#f2f2f2",
  },
};

export default createVuetify({components: {
  VDateInput,
  VTimePicker,
  VNumberInput,
},

  theme: {
    defaultTheme: "myCustomTheme",
    themes: {
      myCustomTheme,
    },
  },
});

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

