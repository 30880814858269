<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.FontSize12px {
  font-size: 12px !important;
  font-family: "GoBold", sans-serif !important;
}
.FontSize14px {
  font-size: 14px !important;
  font-family: "GoBold", sans-serif !important;
}
.FontSize16px {
  font-size: 16px !important;
  font-family: "GoBold", sans-serif !important;
}
.FontSize18px {
  font-size: 18px !important;
  font-family: "GoBold", sans-serif !important;
}
.FontSize20px {
  font-size: 20px !important;
  font-family: "GoBold", sans-serif !important;
}
.FontSize22px {
  font-size: 22px !important;
  font-family: "GoBold", sans-serif !important;
}
.FontSize24px {
  font-size: 24px !important;
  font-family: "GoBold", sans-serif !important;
}
.OTPField .v-otp-input__content {
  padding: 0 !important;
  max-width: 500px !important;
}
.TextFieldVariant1 .v-field__input {
  font-size: 14px !important;
}
.CursorPointerVariant1 {
  cursor: pointer !important;
}
.CustomizedTableClass table {
  border: 1px solid #f2f2f2 !important;
}
.CustomizedTableClass table tr:nth-child(even) {
  background-color: #e5f6f0 !important;
}
.CustomizedTableClass table th {
  position: -webkit-sticky !important;
  top: 0 !important;
  z-index: 0 !important;
  background: #01ab6e !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.OverlayLoader {
  width: 50px;
  aspect-ratio: 1;
  color: #01ab6e;
  position: relative;
  animation: l40 0.5s infinite alternate;
}
.OverlayLoader:before,
.OverlayLoader:after {
  content: "";
  position: absolute;
  inset: 0;
  background-size: 25px 25px;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-repeat: no-repeat;
}
.OverlayLoader:before {
  background-image: radial-gradient(farthest-side at top left, currentColor 96%, #0000), radial-gradient(farthest-side at top right, currentColor 96%, #0000),
    radial-gradient(farthest-side at bottom right, currentColor 96%, #0000), radial-gradient(farthest-side at bottom left, currentColor 96%, #0000);
  animation: l40-1 1s infinite;
}
.OverlayLoader:after {
  background-image: radial-gradient(farthest-side at top left, #0000 94%, currentColor 96%), radial-gradient(farthest-side at top right, #0000 94%, currentColor 96%),
    radial-gradient(farthest-side at bottom right, #0000 94%, currentColor 96%), radial-gradient(farthest-side at bottom left, #0000 94%, currentColor 96%);
  animation: l40-2 1s infinite;
}
@keyframes l40-1 {
  0%,
  10%,
  90%,
  100% {
    inset: 0;
  }
  40%,
  60% {
    inset: -10px;
  }
}
@keyframes l40-2 {
  0%,
  40% {
    transform: rotate(0);
  }
  60%,
  100% {
    transform: rotate(90deg);
  }
}

.CardBorderVariant1{
  border-radius: 0px 0px 0px 0px !important;
  box-shadow: 14px 14px 28px  #9f9b9b !important;
  background-image: linear-gradient(to right,   #4cc499,#f1e185) !important;
  color: #fff !important;
}

</style>

