import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/MainPages/LoginPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/SignUpPage",
    name: "SignUpPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/MainPages/SignUpPage.vue"),
  },
  {
    path: "/OrganizationDetails",
    name: "OrganizationDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/MainPages/OrganizationDetails.vue"),
    children: [
      {
        path: "/Products",
        name: "Products",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Products.vue"),
      },
      {
        path: "/CallLogs",
        name: "CallLogs",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/CallLogs.vue"),
      },
    ],
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/MainPages/LandingPage.vue"),
    children: [
      {
        path: "/Organizations",
        name: "Organizations",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Organizations.vue"),
      },
      {
        path: "/Approvals",
        name: "Approvals",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Approvals.vue"),
      },
      {
        path: "/MyProfile",
        name: "MyProfile",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/MyProfile.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

