import { createStore } from "vuex";

export default createStore({
  state: {
    current_user_details: null,
    current_selected_lead: null,
    is_query_status_enabled: null,
  },
  getters: {
    get_current_user_details: (state) => state.current_user_details,
    get_current_selected_lead: (state) => state.current_selected_lead,
    get_is_query_status_enabled: (state) => state.is_query_status_enabled,
  },
  mutations: {
    set_current_user_details(state, payload) {
      state.current_user_details = payload;
    },
    set_current_selected_lead(state, payload) {
      state.current_selected_lead = payload;
    },
    set_is_query_status_enabled(state, payload) {
      state.is_query_status_enabled = payload;
    },
  },
  actions: {},
  modules: {},
  plugins: [],
});