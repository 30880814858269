<template>
  <div>
    <v-snackbar absolute v-model="SnackbarComponent.SnackbarVmodel" :color="SnackbarComponent.SnackbarColor" location="top">
      <div class="text-center font-weight-bold">
        {{ SnackbarComponent.SnackbarText }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    SnackbarComponent: Object,
  },
};
</script>

<style></style>
